import React from "react"
import { Container } from "../../styles/container"
import { H1 } from "../../styles/headings"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
const DankePage = () => {
  return (
    <Layout>
      <Seo title="Vielen Dank für Ihre Anfrage" />
      <Container fluid boxed center marginTop marginBottom>
        <H1 headingStyle="h3">Vielen Dank für Ihre Anfrage.</H1>
        <p>
          Wir werden uns schnellstmöglich mit Ihnen in Verbindung setzen und
          Ihnen die gewünschten Informationen zur Verfügung stellen. Gerne
          können Sie sich jederzeit auch direkt an uns wenden:{" "}
          <a href="mailto:info@health-inspector.de">info@health-inspector.de</a>
        </p>
      </Container>
    </Layout>
  )
}

export default DankePage
